@import "../../variables.scss";

.Button {
  @include input-styles;

  padding: 0;
  text-decoration: none;

  &.main {
    background: $gradient;
    color: #ffffff;

    //&:hover {
    //  opacity: 0.9;
    //}
  }

  &.secondary {
    background: $secondary-color;
    color: #ffffff;

    //&:hover {
    //  opacity: 0.9;
    //}
  }

  &.disabled {
    background: #cccccc;
    color: #ffffff;
    cursor: not-allowed;

    //&:hover {
    //  opacity: 1;
    //}
  }
}