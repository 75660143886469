.Test {

  & .container {
    background-color: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
    position: relative;
    z-index: 1;
    padding: 24px 32px 32px;

    .submit {
      margin-left: auto;
    }
  }

  & h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .question-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  & .instructions {
    font-size: 1.2rem;
    color: gray;
    margin-bottom: 1rem;
  }

  & .carousel-controls {
    padding: 1rem;
    display: flex;
    justify-content: center;

    & button:first-child {
      margin-right: 1rem;
    }
  }

  & .answer-container {
    padding: 1rem;
    margin-bottom: 1rem;

    & .form-check {
      margin: .33rem;
      font-size: 1.2rem;
    }
  }

  & .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    background-color: green;
  }

  & .carousel .control-dots .dot {
    width: 12px;
    height: 12px;
  }

  & .test-controls {
    display: flex;
    align-items: end;
    width: 100%;
    justify-content: space-between;
  }

  & .answer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
}
