@import "../variables";

.cursor-pointer {
  cursor: pointer;
}

.layout-container {
  display: grid;
  gap: 3rem;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #ededed;
  align-items: start;

  & .content {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}