.FormInput.FormCheckbox {
  display: inline-flex;
  align-items: center;

  & label {
    line-height: 1;
    margin-bottom: 0;
  }

  & input {
    margin-right: 1rem;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }
}