@import '../_variables.scss';

.Header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  flex-direction: column;

  & .shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
    justify-content: center;
    display: flex;
    width: 100%;
    z-index: 1;
  }

  > * {
    width: 100%;
  }

  &__logo {
    display: flex;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    max-width: $max-width;
    width: 100%;

  }

  .controls {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .metropolisForAll {
    display: grid;

    & label {
      text-align: center;
      font-size: 20px;
      background-color: #deb887;
      color: white;
      margin: 0;
      width: 100%;
      line-height: 2;
    }

    & h1 {
      background: $gradient;
      color: #fff;
      font-size: 24px;
      margin-bottom: 0;
      padding: 16px 0;
      text-align: center;
    }
  }
}
