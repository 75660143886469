.CountDown-wrapper {
  margin-bottom: 32px;
  display: inline-flex;
  padding: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
}

.CountDown-wrapper > div {
  padding-top: 16px;
  background-color: #333333;
  border-radius: 10px;
  margin: 16px;
  width: 90px;
  height: 100px;


  & p {
    text-align: center;
    color: white;
    margin: 0;
  }

  & p:first-child {
    font-size: 40px;
    font-family: Barlow-Medium, serif;
  }

  & p:last-child {
    font-size: 16px;
    font-family: Barlow-Medium, serif;
  }
}