.FormText {
  display: flex;
  flex-direction: column;

  & input {
    outline: none;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-clip: padding-box;
    border-radius: 6px;
  }

}