.CustomModal .ReactModal__Content {
  width: 640px;
  height: 360px;
  margin: auto;
  padding: 0 !important;
  z-index: 50;
}

.CustomModal .ReactModal__Content .modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  & .close {
    cursor: pointer;
  }
}

.CustomModal .ReactModal__Content .modal-header .modal-title .close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.CustomModal .ReactModal__Content .modal-header button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.CustomModal .ReactModal__Content .modal-header .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.CustomModal .ReactModal__Content .modal-header .modal-title {
  font-size: 1.25rem;
}

.CustomModal .ReactModal__Content .modal-header .modal-title {
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.5;
}

.CustomModal {

  & .modal-body {
    padding: 1rem;
  }

  & .modal-footer {
    border-top: 1px solid #dee2e6;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0.75rem;

    & button:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .ReactModal__Overlay {
    z-index: 3;
    background-color: rgba(33, 37, 41, .5) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.ReactModal__Content {
    position: initial !important;
    height: initial;
  }
}