div.TestOpenQuestion {
  flex-direction: column;

  & .question {
    max-width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: .5rem;

    & h3 {
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
}