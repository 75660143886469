.Profile {
  & .accordion__heading {
    display: flex;
  }

  & .panel-wrapper {
    display: flex;
    flex-direction: column;
  }

  .backToApp {
    width: 168px;
    margin-bottom: 2rem;
  }

  & .container {
    display: inline-flex;
    flex-direction: column;

    &.testResult {
      & div.row:nth-child(2) {
        font-weight: 600;
      }

      & label {
        width: 200px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    & .heading {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    & .row {
      display: flex;

      & label.heading {
        font-size: 1.2rem;
        width: 400px;
        font-weight: 600;
      }
    }
  }
}