.SignInModal p {
  color: #008b8b;
  cursor: pointer;
  display: flex;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}