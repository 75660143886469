.FormInput {
  margin-bottom: 1rem;

  & .error {
    color: #dc3545;
  }

  &.error input {
    border-color: #dc3545;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

    &:active, &:focus, {
      border-radius: 6px;
      border-color: #dc3545;
      box-shadow: 0 0 0 0.25rem rgba(#dc3545, .25);
    }
  }

  & input {
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
  }

  &.required label.label::before {
    content: '*';
    color: red;
    position: relative;
    display: inline-block;
  }

  & .description {
    color: #856404;
  }

  & label {
    margin-bottom: .5rem;
    display: block;
  }
}