.Course-wrapper {
  display: flex;
}

.Course-wrapper-course {
  display: flex;
  text-align: left;
  background: linear-gradient(90deg, #1a8c72 0%, #93b24f 50%, #1a8c72 100%);
  border-radius: 12px;
  padding-top: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
  width: 100%;
}

.Course-wrapper-course-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 24px 16px;
  width: 100%;

  & .info {
    text-transform: uppercase;
    padding: 0 20px 16px 20px;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 16px;

    & p {
      margin: 0;
      line-height: 1.2;
    }

    & p:not(:first-child) {
      color: #1a8c72;
      font-weight: 600;
    }
  }

  &.elearning {
    background-color: #cee8ff;
  }

  &.online {
    background-color: #ceffca;
  }
}


.Course-wrapper-course-container-header {
  border-bottom: 1px solid #d1d1d1;
  padding: 0 24px 20px 24px;
  margin-bottom: 12px;
}

.Course-wrapper-course-container-header h3 {
  line-height: .8;
  margin-bottom: 0;
  font-weight: 600;
}

.Course-wrapper-course-container-header label {
  line-height: 1;
  color: #1a8c72;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.Course-wrapper-course-container-footer button {
  margin: 24px auto 12px;
}

.Course-wrapper-course-container-footer p {
  margin-bottom: 0;
  line-height: 1;
}

.Course-wrapper-course-container-footer {
  margin-top: auto;
  text-align: center;
  color: red;
}

.Course-wrapper-course-container-footer-enrolled-info {
  color: #1a8c72;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
  border: 1px dashed #d1d1d1;
  border-radius: 8px;
  margin: 0 16px;
  padding: 8px;
}
