body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  overflow: auto;
  padding: 120px;
}

.ReactModal__Body--open.CustomModal {
  overflow: hidden;
}