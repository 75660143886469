
.CourseOverview {
  & .switch-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 48px;
    margin-bottom: 16px;

    & .switch-item {
      border: 2px solid gray;
      cursor: pointer;
      border-radius: 16px;
      font-size: 32px;
      padding: 16px;
      text-align: center;

      & label {
        margin-bottom: 0;
      }

      &.active {
        background-color: #A9D18E;
      }
    }
  }

  & .your-course-container {
    display: grid;
    grid-template-columns: minmax(300px, 25%) 1fr;
    gap: 2rem;

    &.fullscreen {
      grid-template-columns: auto;
    }

    & h3 {
      font-size: 24px;
      font-weight: bold;
    }

    & li {
      font-size: 22px;
    }

    & .rest {
      text-align: left;
    }
  }

  & .block {
    background-color: white;
    border-radius: 12px;
    //box-shadow: 0 4px 4px rgb(0 0 0 / 30%);
    padding: 3rem;
    margin-bottom: 32px;

    & li {
      margin-bottom: 1.2rem;
    }
  }

  & .course-passed {
    background-color: #E2F0D9;
    padding: 1.5rem !important;
  }

  & .course-failed {
    background-color: #FFEEF0;
  }

  & .payment-info-block {
    margin: 16px 0;
    text-transform: uppercase;
    width: 50%;
    display: inline-block;

    & > label:first-child {
      font-weight: 600;
      width: 100%;
      display: flex;
      line-height: 1;
      margin-bottom: 0;
    }

    & > label:last-child {
      color: #1a8c72;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  & .payment-warning-block {
    color: #6e6e6e;
    margin-top: 32px;
    line-height: 1.2;
    font-size: 18px;
  }

  & .payment-info-wrapper-payment {
    text-transform: uppercase;
  }

  & .payment-info-wrapper-payment-buttons > div {
    width: 33%;
    text-align: center;
  }

  & .wrapper-payment-info-wrapper > p {
    font-weight: 600;
    font-size: 20px;
  }

  & .payment-info-wrapper-payment-buttons {
    border: 1px solid green;
    border-radius: 8px;
    padding: 4px 0;
    display: flex;

    & > div > p {
      margin: 0;
      line-height: 1;
      border-radius: 8px;
      text-transform: uppercase;
      padding: 16px;
      color: #1a8c72;
      background-color: white;
    }

    & > div {
      font-family: Barlow-Medium, serif;
      display: inline-block;
      cursor: pointer;
      padding: 0 4px;
    }

    & > div:not(:last-child) {
      border-right: 1px solid gray;
    }
  }

  & .payment-info-wrapper-payment-buttons-selected-option > p {
    background: linear-gradient(90deg, #1a8c72 0%, #93b24f 100%);
    color: white !important;
  }

  & .wrapper-payment-info-wrapper-payment-buttons-options {
    display: flex;

    & button:first-child {
      margin-right: 8px;
    }
  }

  & .course-overview-wrapper-payment-status {
    margin-top: 24px;
  }

  & .course-overview-wrapper-payment-status-wrapper {
    display: flex;
    align-items: center;

    & .payment-badge {
      width: 250px;
      margin-right: 16px;
      font-size: 22px;
      text-align: center;
      text-transform: uppercase;
      padding: 12px 0;
      border-radius: 12px;
    }
  }

  & .other-test-item {
    display: flex;
    align-items: center;
  }

  & .course-other-tests-info {
    background-color: #A9D18E;
    margin-right: 16px;
    border-radius: 16px;

    & label {
      margin-bottom: 0;

      &:last-child {
        padding: 16px 16px 16px 0;
      }

      &:first-child {
        text-transform: uppercase;
        font-size: 18px;
        padding: 0 16px;
        line-height: 2rem;
      }
    }
  }

  & .course-passed {
    display: flex;
    justify-content: space-between;
    font-size: 22px;

  }
}


