.FormSelect {
    position: relative;
}

.FormSelect .empty__option {
    min-height: 40px;
}

.FormSelect.disabled .dropdown__selection {
    cursor: not-allowed;
    background-color: var(--color-disabled);
}