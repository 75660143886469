.TestIntroduction {
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
  position: relative;
  z-index: 1;
  padding: 24px 32px 32px;
  margin: auto;

  & .countdown {
    text-align: center;
    margin-top: 3rem;

    & .CountDown-wrapper {
      box-shadow: none;
    }
  }

  & li {
    line-height: 1.5rem;
    font-size: 18px;
  }

  & h3 {
    font-size: 24px;
  }
}