.CourseSelection {
  &.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 20px;
    width: 100%;
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & .alert {
      width: 100%;
    }
  }

  &-not-available-info {
    width: 100%;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .3);
    background-color: #ffffff;
    color: black;

    & p {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 8px;
    }
  }
}


.CourseSelection-wrapper-countdown {
  margin: 0 auto;
  width: 100%;
}

.CourseSelection-wrapper-countdown-red .count-down-wrapper > div {
  background-color: #ff0000;
}

.CourseSelection-wrapper-info-block {
  width: 100%;
}
