@import "../variables";

.LanguageSelector {

  position: relative;
  display: inline-block;
  min-width: 8rem;
  border-radius: 0.4rem;
  background-color: #fff;
  border: 1px solid $primary-color;

  &__selected {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    padding: 0.4rem 1rem;
    cursor: pointer;
    gap: 8px;
  }

  .separator {
    border-left: 1px solid #ccc;
    height: 1rem;
  }

  .arrow {
    &.down::before {
      content: "▼";
    }

    &.up::before {
      content: "▲";
    }
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 100%;
    z-index: 1;
  }

  .language-option {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;

    & .flag-icon {
      margin-right: 8px;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
