.app-container-rtl .CourseIntroduction div {
  text-align: right;
}

.CourseIntroduction > div {
  text-align: left;
  background-color: white;
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
  margin-bottom: 32px;
}

.CourseIntroduction > div > h2 {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 24px;
}

.CourseIntroduction > div > p {
  list-style-type: circle;
  display: list-item;
  margin-left: 16px;
  padding-left: 8px;
  font-size: 18px;
  margin-bottom: 8px;
}
