.FormDate {
  & input {
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    color: #212529;
    display: block;
    font-weight: 400;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: calc(100% - 0.75rem - 0.75rem);
  }
}